/* stylelint-disable property-no-vendor-prefix */

@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/typography';
@import '@/styles/utils/functions/rem-calc';

.title {
  text-transform: uppercase;
  font-family: var(--pbsk-font-family-headline);
  color: var(--pbsk-theme-text-color);
  font-size: rem-calc(20) !important;
  padding: 0 var(--pbsk-spacing-5) var(--pbsk-spacing-1);
  line-height: 1.2em;

  @include breakpoint(sm) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include breakpoint(md) {
    font-size: rem-calc(26) !important;
  }

  @include breakpoint(lg) {
    font-size: rem-calc(32) !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  @include breakpoint(xl) {
    @include text-md;
    padding: 0 var(--pbsk-spacing-20) var(--pbsk-spacing-2) 0;
  }

  &.emptyTitle {
    line-height: 1em;
    padding-bottom: 0;
  }
}

.embla {
  --slide-spacing: var(--pbsk-spacing-4);
  --slide-size: 80%;
  --slide-height: auto;
  --outline-offset: 12px;
  --slideSpacingPlusOutlineOffset: calc(var(--outline-offset) + var(--slide-spacing));
  position: relative;
  padding: 0;
  width: calc(100vw - var(--pbsk-client-scrollbar-width));
  max-width: 100vw;

  @include breakpoint(xs) {
    --slide-size: 80%;
  }

  @include breakpoint(sm) {
    --slide-spacing: var(--pbsk-spacing-5);
    --slide-size: 50%;
  }

  @include breakpoint(lg) {
    --slide-size: calc(100% / 3);
    --slide-spacing: var(--pbsk-spacing-6);
    max-width: 100%;
  }

  @include breakpoint(xl) {
    --slide-spacing: var(--pbsk-spacing-8);
  }

  @media (max-width: $breakpoint-sm) {
    margin: 0;

    button {
      background-color: transparent !important;
      opacity: 1;
    }
  }

  @media (max-width: $breakpoint-xs) {
    button svg {
      width: 14px;
    }
  }
}

@mixin fadeAway {
  @include breakpoint(lg) {
    content: '';
    height: 100%;
    width: calc(var(--slide-spacing) * 3);
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, var(--pbsk-theme-bodyBackgroundColor) 0%, var(--pbsk-theme-bodyBackgroundColor) 33%, transparent 66%, transparent 100%);
    pointer-events: none;
    z-index: 1;
  }

  @media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
    background: linear-gradient(90deg, var(--pbsk-theme-bodyBackgroundColor) 0%, var(--pbsk-theme-bodyBackgroundColor) calc(var(--slide-spacing) * 3), transparent calc(var(--slide-spacing) * 4), transparent 100%);
  }
}

.emblaViewport {
  position: relative;
  padding: 1rem var(--slide-spacing) 0;
  width: 100%;
  margin: 0;
  overflow: hidden;

  @include breakpoint(xl) {
    width: calc(100% + (var(--slide-spacing) * 2));
    padding: 1rem calc(var(--outline-offset)) 0;
    margin-left: calc(var(--slide-spacing) * -1);
  }

  &.isDragging {
    @media (max-width: $breakpoint-sm) {
      img, div {
        -webkit-mask-size: 150% !important;
        mask-size: 150% !important;
      }
    }
  }

  &::before {
    @include fadeAway;

    @include breakpoint(lg) {
      left: calc(var(--slide-spacing) * -1);
    }

    @media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
      left: calc(var(--slide-spacing) * -2.5);
      width: calc(var(--slide-spacing) * 4);
    }
  }

  &::after {
    @include fadeAway;

    @include breakpoint(lg) {
      transform: scaleX(-1);
      right: calc(var(--slide-spacing) * -1);
    }

    @media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
      right: calc(var(--slide-spacing) * -2.5);
      width: calc(var(--slide-spacing) * 4);
    }
  }
}


.emblaContainer {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.emblaSlide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  list-style: none;

  @media (max-width: $breakpoint-sm) {
    img {
      mask-image: url('/puma/images/icons/NavButtonLeft.svg'), url('/puma/images/icons/NavButtonRight.svg');
      -webkit-mask-size: 150%;
      mask-size: 150%;
      mask-position: right, left;
      mask-repeat: no-repeat;
      transition: mask .5s ease, filter .3s ease;
    }

    svg {
      opacity: 1;
    }
  }
}

$custom-button-breakpoint: 1150px;
$custom-button-query: 'screen and (min-width: 1150px)';

.normalNav {
  display: block;
}

.specialNav {
  display: none;
}

.navButton {
  position: absolute !important;
  transform: translateY(-50%);
  top: 50%;
  z-index: 1;

  @media (max-width: $breakpoint-sm) {
    svg {
      fill: var(--pbsk-color-white);
    }
  }

  &.navLeft {
    left: 0;


    @include breakpoint($custom-button-query) {
      left: unset;
      right: 100%;
    }

    @include breakpoint(xl) {
      right: calc(100% + var(--slide-spacing));
    }

    @media (max-width: $breakpoint-sm) {
      svg {
        left: 40%;
      }
    }
  }

  &.navRight {
    right: 0;

    @include breakpoint($custom-button-query) {
      right: unset;
      left: 100%;
    }

    @include breakpoint(xl) {
      left: calc(100% + var(--slide-spacing));
    }


    @media (max-width: $breakpoint-sm) {
      svg {
        left: 60%;
      }
    }
  }
}

.navWrapper {
  @media (min-width: $breakpoint-lg) and (max-width: calc($custom-button-breakpoint - 1px)) {
    display: block;
    position: absolute;
    width: calc(100vw - var(--pbsk-client-scrollbar-width));
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;

    .navButton {
      height: 105px;
      width: 64px;
    }

    .navButton svg {
      width: 29px;
    }

    .navLeft {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .navRight {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.poster {
  --slide-size: 38%;

  @include breakpoint(xs) {
    --slide-size: 40%;
  }

  @include breakpoint(sm) {
    --slide-size: 25%;
  }
}

.largeSquare {
  --slide-size: 38%;

  @include breakpoint(xs) {
    --slide-size: 40%;
  }

  @include breakpoint(sm) {
    --slide-size: 25%;
  }

  .emblaSlide {
    max-height: 230px;
  }
}

.smallSquare {
  --slide-size: 28%;

  @include breakpoint(sm) {
    --slide-size: calc(100% / 6);
  }

  button {
    width: 28px;

    @include breakpoint(xs) {
      width: 35px;
    }

    @include breakpoint(sm) {
      width: 46px;
    }

    @include breakpoint(lg) {
      width: 60px;
    }
  }
}

.poster, .largeSquare, .smallSquare {
  .emblaViewport {
    padding: 1rem var(--slide-spacing);

    @media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
      &::before {
        left: calc(var(--slide-spacing) * -3);
      }

      &::after {
        right: calc(var(--slide-spacing) * -3);
      }
    }
  }

  .emblaSlide {
    @media (max-width: $breakpoint-sm) {
      div {
        mask-image: url('/puma/images/icons/NavButtonLeft.svg'), url('/puma/images/icons/NavButtonRight.svg');
        -webkit-mask-size: 150%;
        mask-size: 150%;
        mask-position: right, left;
        mask-repeat: no-repeat;
        transition: mask .5s ease;
      }

      &:not(.slideInView), &:not(.slideSnapped) {
        article {
          background-color: transparent;

          &:hover {
            box-shadow: none;
          }
        }
      }

      &:not(.slideInView, .slideSnapped) {
        pointer-events: none;

        img, div {
          position: relative;
          mask-image: url('/puma/images/icons/NavButtonLeft.svg'), url('/puma/images/icons/NavButtonRight.svg');
          mask-position: right, left;
          -webkit-mask-size: 35%;
          mask-size: 35%;
          mask-repeat: no-repeat;
          filter: brightness(.5);
        }
      }

      &.slideInView {
        transition: none;
      }
    }
  }
}

.mezzanine {
  .emblaViewport {
    --slideSpacingPlusOutlineOffset: calc(var(--outline-offset) + var(--slide-spacing));
    padding-top: 1.5rem;
    margin-top: -.5rem;
    padding-bottom: 1rem;

    @include breakpoint(xl) {
      width: calc(100% + var(--slideSpacingPlusOutlineOffset) * 2);
      padding: 1.5rem var(--slideSpacingPlusOutlineOffset) 1rem;
      margin-left: calc(var(--slideSpacingPlusOutlineOffset) * -1);
    }
  }

  .emblaContainer {
    @include breakpoint(sm) {
      margin-left: calc(var(--slide-spacing) * -1);
    }
  }

  .emblaSlide {
    opacity: 1;
    transition: opacity .2s ease;

    @media (max-width: $breakpoint-sm) {
      &:not(.slideSnapped) {
        pointer-events: none;

        [data-use-loading-dots] {
          background-color: transparent;
          background-image: none;
        }

        article {
          background-color: transparent;
        }

        img {
          position: relative;
          mask-image: url('/puma/images/icons/NavButtonLeft.svg'), url('/puma/images/icons/NavButtonRight.svg');
          -webkit-mask-size: 15%;
          mask-size: 15%;
          filter: brightness(.5);
        }

        a, span {
          opacity: 0;
        }

        article:hover {
          & > * {
            box-shadow: none;
          }
        }

        svg {
          opacity: 0;
        }
      }
    }

    &:not(.slideInView) {
      @include breakpoint(xxl) {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .navButton {
    transform: translateY(-50%);
    top: calc(50% - 25px);

    @include breakpoint(sm) {
      transform: none;
      top: 10vw;
    }

    @include breakpoint(md) {
      top: 11vw;
    }

    @include breakpoint(lg) {
      top: 18%;
    }
  }

  .emptyTitle {
    + .mezzanine .navButton {
      @include breakpoint(lg) {
        top: 25%;
      }
    }
  }

  .navLeft {
    @include breakpoint(xl) {
      left: unset;
      right: calc(100% + var(--slide-spacing));
    }
  }

  .navRight {
    @include breakpoint(xl) {
      right: unset;
      left: calc(100% + var(--slide-spacing));
    }
  }

  .navWrapper {
    @media (min-width: 1024px) and (max-width: 1149px) {
      top: 18%;
    }
  }
}
