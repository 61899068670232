@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/functions/rem-calc';

.mediaBadgeNew {
  position: absolute;
  justify-items: center;
  width: var(--pbsk-spacing-16);
  height: var(--pbsk-spacing-16);
  border-radius: 50%;
  color: var(--pbsk-theme-calloutTextColor);
  background-color: var(--pbsk-theme-calloutBackgroundColor);
  border: 8px solid var(--pbsk-theme-bodyBackgroundColor);
  font-family: var(--pbsk-font-family-headline);
  text-transform: uppercase;
  text-align: center;
  font-size: rem-calc(16);
  line-height: rem-calc(48);

  @include standardTransition($duration: 150ms);

  &.inMasthead,
  [data-hover-group='media-card']:hover & {
    border-width: 0;
    font-size: rem-calc(22);
    line-height: rem-calc(64);
  }
}

.smallBadge {
  width: 50px;
  height: 50px;
  border-width: 7px;
  font-size: 12px;
  line-height: 37px;
}

.mediaBadgeText {
  width: var(--pbsk-spacing-4);
  height: var(--pbsk-spacing-4);
  margin-right: calc(var(--pbsk-spacing-1) * 1.5);
}

.mediaBadgeTextIconWrapper {
  display: inline-flex;
  align-items: center;
  font-family: var(--pbsk-font-family-headline);
  text-transform: uppercase;
  font-size: 14px;
  color: var(--pbsk-theme-badgeTextColor);
  background-color: rgb(var(--pbsk-theme-badgeBackgroundColor-rgb) / 80%);
  border-radius: 9999px;
  padding: var(--pbsk-spacing-1) var(--pbsk-spacing-4);

  svg {
    fill: var(--pbsk-theme-badgeTextColor);
  }
}
