@mixin drop-shadow($size: md) {
  @if $size == md {
    filter:
      drop-shadow(rgb(0 0 0 / 7%) 0 4px 3px)
      drop-shadow(rgb(0 0 0 / 6%) 0 2px 2px);
  }
}

@mixin box-shadow-border($color, $width: var(--pbsk-focus-outline-width)) {
  box-shadow: #fff 0 0 0 0,
    $color 0 0 0 $width,
    #000 0 0 0 0;
}
