@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';

.innerWrapper {
  --pbsk-component-page-section-stack-padding-x: var(--pbsk-spacing-5);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 30px 0 var(--pbsk-spacing-15);
  max-width: var(--pbsk-max-lower-modules-width);

  --pbsk-component-page-section-stack-module-gap: var(--pbsk-spacing-12);

  @include breakpoint(lg) {
    --pbsk-component-page-section-stack-padding-x: var(--pbsk-spacing-6);
    --pbsk-component-page-section-stack-module-gap: var(--pbsk-spacing-15);
  }

  // !!!
  //  gap for flexbox not supported on iOS 13 and below <https://caniuse.com/flexbox-gap>
  //  This `supports` workaround found here: <https://stackoverflow.com/a/77517366>
  // !!!
  @supports (inset: 0) {
    gap: var(--pbsk-component-page-section-stack-module-gap);
  }

  @supports not (inset: 0) {
    & > * {
      margin-top: calc(.5 * var(--pbsk-component-page-section-stack-module-gap)) !important;
      margin-bottom: calc(.5 * var(--pbsk-component-page-section-stack-module-gap)) !important;
    }
  }

  @include breakpoint(xl) {
    --pbsk-component-page-section-stack-padding-x: 0;
  }

  & > * {
    padding-left: var(--pbsk-component-page-section-stack-padding-x);
    padding-right: var(--pbsk-component-page-section-stack-padding-x);
  }

  .isSlider {
    padding: 0;
  }

  .isPropertyNav {
    @include breakpoint(lg) {
      margin-left: calc(-1 * (100vw - 100% - var(--pbsk-client-scrollbar-width)) / 2);
    }
  }
}

.wider {
  max-width: var(--pbsk-max-lower-game-modules-width);
}

.backgroundOffset {
  @include breakpoint(lg) {
    padding-top: 240px;
  }
}
