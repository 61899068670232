@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/shadows';
@import '@/styles/utils/mixins/typography';
@import '@/styles/utils/functions/rem-calc';

.mediaItem {
  position: relative;
}

.mediaItemLink {
  cursor: pointer;
}

.mediaCardDetailsContainer {
  font-family: var(--pbsk-font-family-sans);

  @include text-sm;
  text-align: center;
  color: var(--pbsk-theme-bodyTextColor);
  padding-top: var(--pbsk-spacing-4);
  padding-bottom: var(--pbsk-spacing-4);
  margin: 0 auto;
  width: 85%;
  overflow: hidden;

  .heading, p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .heading {
    font-family: var(--pbsk-font-family-sans-bold);
    font-size: 1.17em;
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.25em;
  }

  .linkCardTitle {
    white-space: normal;
  }

  @include breakpoint(sm) {
    padding-bottom: 0;
  }

  @include breakpoint(lg) {
    @include text-md;
  }

  &.doubleStacked p {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
  }
}

.newBadgeWrapper {
  position: absolute;
  top: calc(-1 * var(--pbsk-spacing-5));
  left: calc(-1 * var(--pbsk-spacing-5));
}

.mediaBadgeWrapper {
  position: absolute;
  bottom: var(--pbsk-spacing-3);
  left: var(--pbsk-spacing-3);
}

.musicBadgeIcon {
  position: absolute;
  width: 50%;
  height: auto;
  bottom: 0;
  pointer-events: none;
}

.movieBadgeLabel {
  background-color: rgb(var(--pbsk-theme-badgeBackgroundColor-rgb) / 80%) !important;
}

.mediaImage {
  border-radius: 20px;
}

.mediaLink {
  display: block;

  &:focus {
    outline: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.videoCard {
  position: relative;
  margin-bottom: 1em;

  a:focus {
    outline: none;
  }
}

.imageContainer {
  position: relative;
  border-radius: var(--pbsk-spacing-5);
  overflow: hidden;

  @include standardTransition($properties: box-shadow);

  .inMasthead &[data-use-loading-dots] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  [data-hover-group='media-card']:hover &,
  [data-hover-group='media-card']:focus-within & {
    @include box-shadow-border(var(--pbsk-component-media-item-hover-border-color));
  }
}

.imageOverlay {
  content: '';
  position: absolute;

  // Safari < 14.5 does not support the `inset` property :'(
  // https://caniuse.com/mdn-css_properties_inset
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--pbsk-color-black);
  opacity: .5;
  transition: opacity .2s ease;
}

.videoLength {
  background-color: var( --pbsk-color-medium-gray);
  position: absolute;
  right: var(--pbsk-spacing-3);
  bottom: var(--pbsk-spacing-3);
  color: white;
  padding: rem-calc(1) rem-calc(12);
  border-radius: 12px;
  line-height: 1.5;
}

.inMasthead {
  position: relative;
  background-color: var(--pbsk-theme-bodyBackgroundColor);
  border-radius: var(--pbsk-spacing-5);
  margin-bottom: var(--pbsk-spacing-5);

  @include standardTransition($properties: box-shadow);

  @include breakpoint(lg) {
    margin-bottom: var(--pbsk-spacing-8);
  }

  .mediaImage {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .newBadgeWrapper .mediaBadgeNew {
    border: none;
  }

  .newBadgeWrapper {
    top: calc(-1 * var(--pbsk-spacing-3));
    left: calc(-1 * var(--pbsk-spacing-3));
  }

  &[data-hover-group='media-card']:hover,
  &[data-hover-group='media-card']:focus-within {
    @include box-shadow-border(var(--pbsk-component-media-item-hover-border-color));

    .imageContainer {
      box-shadow: none;
    }
  }
}

.linkCard {
  position: relative;
  height: 100%;
  border-radius: 15px;
  font-size: 0;
  overflow: hidden;

  @include standardTransition($properties: box-shadow);

  &:hover,
  &:focus-within {
    @include box-shadow-border(var(--pbsk-component-media-item-hover-border-color));
  }
}

.square {
  .linkCard {
    border-radius: 20px;
  }
}

.podcast {
  .linkCard {
    border-radius: 12px;

    @include breakpoint(sm) {
      border-radius: 16px;
    }

    @include breakpoint(lg) {
      border-radius: 20px;
    }
  }
}

.poster {
  .linkCard {
    border-radius: 12px;

    @include breakpoint(md) {
      border-radius: 15px;
    }
  }
}

.hasTitle {
  border-radius: 0;
  overflow: visible;

  .mediaContainer {
    height: auto;
    border-radius: var(--pbsk-spacing-5);

    @include standardTransition($properties: box-shadow);
  }

  img {
    height: auto;
    border-radius: var(--pbsk-spacing-5);
  }

  &.linkCard:focus-within {
    box-shadow: none;
  }

  &[data-hover-group='media-card']:hover,
  &[data-hover-group='media-card']:focus-within {
    .mediaContainer {
      @include box-shadow-border(var(--pbsk-component-media-item-hover-border-color));
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: none;
  }
}
